<template>
	<div>
		<v-card id="printPage" flat>
			<v-card-title class="primary justify-center py-4 title">
				<span class="text-h6 white--text text-uppercase"
					>{{ payslipTitle }}</span
				>
			</v-card-title>
			<v-card-text>
				<div class="payslip-date py-2 custom-padding">
					<span class="payslip-style"> Payslip for {{ getDate }} </span>
				</div>
				<div
					class="payslip-date d-flex justify-space-between py-2 px-13 custom-padding"
				>
					<label
						><strong class="payslip-style"
							>SSNIT Office Complex</strong
						></label
					>
					<label
						><strong class="payslip-style"
							>27 Ama Akroma Street</strong
						></label
					>
					<label
						><strong class="payslip-style">Takoradi - Ghana</strong></label
					>
				</div>
			</v-card-text>

			<div>
				<!-- employee info -->
				<div class="w-100 px-16 payslip-style">
					<v-row class="px-3">
						<v-col cols="5" class="d-flex justify-content-between">
							<strong>Staff Name:</strong>
							<span>{{
								data.displayName
									? data.displayName
									: data.firstName + " " + data.lastName
							}}</span>
						</v-col>
						<v-col cols="2"></v-col>
						<v-col cols="5" class="d-flex justify-content-between">
							<strong>Staff ID:</strong>
							<span>{{ data.employeeId }}</span>
						</v-col>
					</v-row>

					<v-row class="my-0 px-3">
						<v-col cols="5" class="d-flex justify-content-between py-1">
							<strong>Department:</strong>
							<span>{{
								data.department ? data.department : " - "
							}}</span>
						</v-col>
						<v-col cols="2"></v-col>
						<v-col cols="5" class="d-flex justify-content-between py-1">
							<strong>Job Description:</strong>
							<span>{{ data.position }}</span>
						</v-col>
					</v-row>

					<v-row class="my-0 px-3">
						<v-col cols="5" class="d-flex justify-content-between py-1">
							<strong>Email:</strong>
							<span>{{ data.workEmail }}</span>
						</v-col>
						<v-col cols="2"></v-col>
						<v-col cols="5" class="d-flex justify-content-between py-1">
							<strong>Annual Basic Salary:</strong>
							<span>{{ data.basicSalary * 12 }}</span>
						</v-col>
					</v-row>

					<v-row class="my-0 px-3">
						<v-col cols="5" class="d-flex justify-content-between py-1">
							<strong>Bank Name:</strong>
							<span>{{ data.bankName }}</span>
						</v-col>
						<v-col cols="2"></v-col>
						<v-col cols="5" class="d-flex justify-content-between py-1">
							<strong>Branch:</strong>
							<span>{{ data.branchName }}</span>
						</v-col>
					</v-row>

					<v-row class="my-0 px-3">
						<v-col cols="5" class="d-flex justify-content-between py-1">
							<strong>Account Number:</strong>
							<span>{{ data.accountNumber }}</span>
						</v-col>
						<v-col cols="2"></v-col>
						<v-col cols="5" class="d-flex justify-content-between py-1">
							<strong>SSNIT Number:</strong>
							<span>{{ data.customSSN }}</span>
						</v-col>
					</v-row>
				</div>

				<!-- earnings -->
				<v-row class="px-16 mt-12">
					<v-col cols="12" sm="12" md="12" class="px-7 payslip-style">
						<label class="custom-padding"
							><strong>Earnings</strong></label
						>
						<table aria-label="payroll-table">
							<tbody>
								<tr>
									<th
										scope="row"
										class="text-left py-3 px-5 custom-padding custom-left"
									>
										Basic Salary
									</th>
									<td
										class="text-right py-3 px-5 custom-padding custom-right"
									>
										GH¢ {{ data.basicSalary }}
									</td>
								</tr>
								<tr v-for="(bonus, b) in bonuses" :key="`bonus_${b}`">
									<th
										scope="row"
										class="text-left py-3 px-5 custom-padding custom-left"
									>
										{{ bonus.description }}
									</th>
									<td
										class="align-content-end py-3 px-5 custom-padding custom-right"
									>
										GH¢ {{ bonus.monthlyBonus }}
									</td>
								</tr>
								<tr v-for="(bonus, b) in bonuses" :key="`bonus_${b}`">
									<th
										scope="row"
										class="py-3 px-5 custom-padding custom-left"
									>
										Total Earnings
									</th>
									<td class="py-3 custom-padding custom-right">
										<strong
											>GH¢
											{{
												bonus.monthlyBonus + data.basicSalary
											}}</strong
										>
									</td>
								</tr>
							</tbody>
						</table>
					</v-col>
				</v-row>
				<v-row class="px-16 mt-12">
					<v-col cols="12" sm="12" md="12" class="px-7 payslip-style">
						<label><strong>Deductions</strong></label>
						<table aria-label="">
							<tbody>
								<tr>
									<th
										scope="row"
										class="text-left py-3 px-5 custom-padding"
									>
										Employee 5.5% SSF
									</th>
									<td class="text-right py-3 px-5 custom-padding">
										GH¢ {{ data.employeeSSF }}
									</td>
								</tr>
								<tr>
									<th
										scope="row"
										class="text-left py-3 px-5 custom-padding"
									>
										P.A.Y.E.
									</th>
									<td class="text-right py-3 px-5 custom-padding">
										GH¢ {{ data.paye }}
									</td>
								</tr>
								<tr>
									<th
										scope="row"
										class="text-left py-3 px-5 custom-padding"
									>
										Tax On Bonuses
									</th>
									<td class="text-right py-3 px-5 custom-padding">
										GH¢ {{ data.totalTaxOnBonus }}
									</td>
								</tr>
								<tr>
									<th
										scope="row"
										class="text-left py-3 px-5 custom-padding"
									>
										Total Deductions
									</th>
									<td class="text--right py-3 px-5 custom-padding">
										<strong
											>GH¢
											{{
												data.employeeSSF +
												data.paye +
												data.totalTaxOnBonus
											}}</strong
										>
									</td>
								</tr>
							</tbody>
						</table>
					</v-col>
				</v-row>
				<v-card-text>
					<div
						class="table-border d-flex justify-space-between mt-10 py-2 px-13 custom-padding custom-margin"
					>
						<label
							><strong class="payslip-style">Net Salary</strong></label
						>
						<label
							><strong class="payslip-style"
								>GH¢ {{ data.netSalary.toFixed(2) }}</strong
							></label
						>
					</div>
				</v-card-text>
			</div>
			<v-card-actions class="justify-center">
				<v-btn color="primary" depressed v-print="printObj">
					Print Slip
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>
import moment from "moment";
export default {
	props: ["data", "deductions", "allowances", "bonuses"],
	data() {
		return {
			dialog: false,
			printObj: {
				id: "printPage",
				standard: "html5",
			},
		};
	},
	computed: {
		getDate() {
			const date = new Date();
			return moment(date).format("MMMM, YYYY");
		},
		payslipTitle() {
			let prefix = this.data.employee.employeeId.slice(0, 2);
			let title = ""
			if(prefix === "SC" || prefix === "OP") {
				title = "Amalitech Ghana Limited";
			}else {
				title = "Amalitech Training"
			}
			return title.toUpperCase();
		},
		/*get the payslip for the current month*/
		getCurrentPayslip() {
			if (this.data.payroll && this.data.payroll.length > 0) {
				return (
					this.data.payroll.filter((payslip) => {
						return (
							payslip.month === this.currentMonthPayslip &&
							payslip.year === this.currentYearPayslip
						);
					}) || []
				);
			} else {
				return [];
			}
		},
		currentMonthPayslip() {
			return new Date().getMonth() + 1;
		},
		currentYearPayslip() {
			return new Date().getFullYear();
		},
		sumBonuses() {
			const accumulatedBonuses = [];
			this.payroll.map((pay) => {
				const totalAllowance = this.sumArrayItems(
					this.getAMount(pay.allowance, "allowance")
				);
				const totalBonus = this.sumArrayItems(
					this.getAMount(pay.allowance, "bonus")
				);
				const totalAmount = totalAllowance + totalBonus;
				// return totalAmount
				accumulatedBonuses.push(totalAmount);
			});
			return accumulatedBonuses;
		},

		getPayroll() {
			return this.payroll.map((bonus) => {
				return bonus;
			});
		},
	},
	method: {
		getAMount(arr, type) {
			let allAmount = [];
			arr.map((item) => {
				if (type === "bonus") {
					allAmount.push(item.totalBonus);
				} else if (type === "allowance") {
					allAmount.push(item.totalAllowance);
				}
			});
			return allAmount;
		},
		sumArrayItems(arr) {
			return arr.reduce((a, b) => {
				return a + b;
			}, 0);
		},
		getDate(date) {
			const month = moment(date, "YYYY-M").format("MMMM");
			const year = moment(date, "YYYY-M").format("YYYY");
			return `${month}  ${year}`;
		},
	},
};
</script>
<style scoped>
.payslip-style {
	font-family: "Times New Roman", Times, serif;
	color: black;
}
.payslip-date {
	border-bottom: 3px solid #cf4f1f;
	font-weight: bold;
	text-align: center;
}
.table-border {
	border-bottom: 3px solid #cf4f1f;
	border-top: 3px solid #cf4f1f;
	font-size: 1.2rem;
	padding: 0.2rem;
}
.title {
	color: #fff;
	background-color: #cf4f1f;
	font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
	font-size: 1.8rem;
	justify-content: center;
}
.custom-right {
	align-content: right;
	padding-right: -1rem;
}
.custom-left {
	align-content: left;
}
.custom-padding {
	padding: 0.6rem;
}
.custom-margin-top {
	margin-top: -0.9rem;
}

table {
	border-collapse: collapse;
	width: 100%;
}
tr {
	border: 2px solid #dadada;
}
th {
	text-align: left;
}
td {
	text-align: right;
}
</style>
